


export const data = [
    {
        id: 1,
        name: 'Saree',
        category: 'saree',
        image: require('../assets/products/dress1.jpeg'),
        price: 2000

    },
    {
        id: 2,
        name: 'Kamiz',
        category: 'dress',
        image: require('../assets/products/dress2.jpeg'),
        price: 2000

    },
    {
        id: 3,
        name: 'Cloth',
        category: 'dress',
        image: require('../assets/products/dress3.jpeg'),
        price: 2000

    },
    {
        id: 4,
        name: 'Saree',
        category: 'saree',
        image: require('../assets/products/dress4.jpeg'),
        price: 2000

    },
    {
        id: 5,
        name: 'Saree',
        category: 'dress',
        image: require('../assets/products/dress5.jpeg'),
        price: 2000

    },
    {
        id: 6,
        name: 'Saree',
        category: 'saree',
        image: require('../assets/products/dress6.jpeg'),
        price: 2000

    }
    


]

